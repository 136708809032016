.candidateForm{
    width: 95%;
    /* background-color: rgb(250, 246, 246); */
    /* background-color: rgb(240, 240, 240); */
   border-radius: 10px;
    margin: auto;
    height: 18rem;
    /* margin-top: 100px; */
    margin-bottom: 100px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* padding: 40px; */
}


.text{
    text-align: center;
    margin-bottom: 20px;
    color: cyan;
}

@media screen and (max-width:950px){
    
    .candidateForm{
        width:90%;
        height: 27rem;
    }
    
}

/* Display Candidate Data css */
.candidateTable{
    width: 100%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;
    font-family: Arial, Helvetica, sans-serif;
}
.candidateRow{
    font-weight: bold;
    background-color: #0468aa;
    color: white;
    min-width: 100px;
}