.signup{
    width: 40%;
    /* background-color: rgb(240, 240, 240); */
    border: 1px solid grey;
   border-radius: 10px;
    margin: auto;
    height: 50rem;
    margin-top: 100px;
    margin-bottom: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px;
    /* margin-left: 190px; */
}
.signup1{
    width: 40%;
    /* background-color: rgb(240, 240, 240); */
    border: 1px solid grey;
   border-radius: 10px;
    margin: auto;
    height: 42rem;
    margin-top: 100px;
    margin-bottom: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px;
    /* margin-left: 190px; */
}
.signupRow{
    display: flex;
    margin: auto;
    justify-content: space-between;
    margin-top: 2vw;
}
.signupButton{
    background-color: #1A1A57!important;
    color: white!important;
    /* display: flex;
    margin: auto; */
    margin-top: 15px!important;
}
.signupButton:hover{
    background-color: #1A1A57!important;
    color: white!important;
}
.singleSignup{
    width: 40%;
    /* background-color: rgb(240, 240, 240); */
    border: 1px solid grey;
   border-radius: 10px;
    margin: auto;
    height: 30rem;
    margin-top: 100px;
    margin-bottom: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px;
    /* margin-left: 190px; */
}
.singleSignup1{
    width: 40%;
    /* background-color: rgb(240, 240, 240); */
    border: 1px solid grey;
   border-radius: 10px;
    margin: auto;
    height: 27rem;
    margin-top: 100px;
    margin-bottom: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px;
    /* margin-left: 190px; */
}

.companySignup{
    width: 40%;
    /* background-color: rgb(240, 240, 240); */
    border: 1px solid grey;
   border-radius: 10px;
    margin: auto;
    height: 38rem;
    margin-top: 100px;
    margin-bottom: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px;
    /* margin-left: 190px; */
}
.companySignup1{
    width: 40%;
    /* background-color: rgb(240, 240, 240); */
    border: 1px solid grey;
   border-radius: 10px;
    margin: auto;
    height: 35rem;
    margin-top: 100px;
    margin-bottom: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px;
    /* margin-left: 190px; */
}
.text{
    text-align: center;
    margin-bottom: 20px;
    color: cyan;
}

@media screen and (max-width:950px){
    
    .signup{
        width:90%;
        height: 27rem;
    }
    
}