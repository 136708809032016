.usersNavbar{
    width:65%;
    display: flex;
    margin: auto;
    justify-content: space-around;
}
.usersLink{
    text-decoration: none;
    color: rgb(87, 87, 87);
    font-weight: bold;
    font-size: larger;
}
.searchbarAdd{
    width:65%;
    display: flex;
    margin: auto;
    /* justify-content: space-between; */
    margin-top: 40px;
}
.usersDataCard{
    width:80%;
    display: flex;
    margin-left: 260px;
    margin-top: 60px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .usersTable{
    width: 100%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;
    font-family: Arial, Helvetica, sans-serif;
}
.usersRow{
    font-weight: bold;
    background-color: #0468aa;
    color: white;
    /* min-width: 100px; */
}