.forgotPasswordText{
    color: #1A1A57;
    cursor: pointer;
}
.forgotPasswordDialogBox{
    width: 90%;
}
.forgotPasswordInput{
    width: 100%;
    margin-top: 15px;

}
.forgotPasswordTitle{
    width: 90%;
   font-size: 18px;

}
.forgotPasswordHeadTitle{
    font-weight: bold;
    font-size: 22px;
}

.forgotPasswordCancelBtn{
    margin-right: 1.3vw!important;
    background-color: rgb(207, 207, 214)!important;
    color: rgb(26, 25, 25)!important;
    font-size: 1vw!important;
    font-weight: bold!important;
}
.forgotPasswordContinueBtn{
    margin-right: 2vw!important;
    font-weight: bold!important;
    background-color: rgb(0, 100, 255)!important;
    color: white!important;
    font-size: 1vw!important;
}

/* OTP */
.otpDialogBox{
    width: 90%!important;
    display: flex!important;
    justify-content: center!important;
}
.otpInput{
    width: 40%!important;
    margin-top: 15px!important;

}
.otpTitle{
    width: 90%!important;
   font-size: 20px!important;

}
.otpHeadTitle{
    font-weight: bold!important;
    font-size: 24px!important;
}
.otpCancelBtn{
    margin-right: 1.3vw!important;
    background-color: rgb(207, 207, 214)!important;
    color: rgb(26, 25, 25)!important;
    font-size: 1vw!important;
    font-weight: bold!important;
}
.otpContinueBtn{
    margin-right: 1.3vw!important;
    font-weight: bold!important;
    background-color: rgb(0, 100, 255)!important;
    color: white!important;
    font-size: 1vw!important;
}
.otpResendBtn{
    margin-right: 1.3vw;
    font-size: 1vw;
    font-weight: bold;
}
.timer{
    color: brown;  margin-right: 15vw;font-size:1vw
}

/* New Password */
.newPasswordCancelBtn{
    margin-right: 1.3vw!important;
    background-color: rgb(207, 207, 214)!important;
    color: rgb(26, 25, 25)!important;
    font-size: 1vw!important;
    font-weight: bold!important;
}
.newPasswordContinueBtn{
    margin-right: 2vw!important;
    font-weight: bold!important;
    background-color: rgb(0, 100, 255)!important;
    color: white!important;
    font-size: 1vw!important;
}
.newPasswordDialogBox{
    width: 90%!important;
}
.newPasswordInput{
    width: 100%!important;
    margin-top: 7px!important;
    /* height: 80px!important; */

}

.newPasswordHeadTitle{
    width: 90%;
    font-weight: bold!important;
    font-size: 24px!important;
}
.newPasswordTitle{
    width: 90%!important;
    font-size: 16px!important;
}
@media (max-width: 912px) {
    .forgotPasswordDialogBox{
        width: 90%!important;
    }
    /* .forgotPasswordInput label{
        width: 100%!important;
        margin-top: 15px!important;
        height: 80px!important;
    
    } */
    .forgotPasswordInput label{
        font-size: 3vw!important;
        font-weight: bold;
    }
    .forgotPasswordTitle{
        width: 90%!important;
       font-size: 26px!important;
    
    }
    .forgotPasswordHeadTitle{
        font-weight: bold!important;
        font-size: 30px!important;
    }
    .forgotPasswordCancelBtn{
        margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: #1a1919!important;
        font-size: 2.5vw!important;
    }
    .forgotPasswordContinueBtn{
        margin-right: 3vw!important;
        font-weight: bold!important;;
        background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-size: 2vw!important;
}

/* OTP */
.otpDialogBox{
    max-width: 800px!important;;
    width: 100%!important;;
    display: flex!important;;
    justify-content: center!important;;
}
.otpInput{
    width: 40%!important;;
    margin-top: 15px!important;;

}
.otpInput label{
    font-size: 3vw!important;

}
.otpTitle{
    width: 90%!important;;
   font-size: 24px!important;;

}
.otpHeadTitle{
    font-weight: bold!important;;
    font-size: 28px!important;;
}
.otpCancelBtn{
    margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: rgb(26, 25, 25)!important;
        font-size: 2vw!important;
}
.otpContinueBtn{
    margin-right: 3vw!important;
        font-weight: bold!important;;
        background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-size: 2vw!important;
}
.otpResendBtn{
    margin-right: 1.3vw!important;;
    font-size: 2vw!important;;
    font-weight: bold!important;;
}
.timer{
    color: brown;  margin-right: 15vw;font-size:2.5vw
}

/* New Password */
.newPasswordCancelBtn{
    margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: rgb(26, 25, 25)!important;
        font-size: 2.5vw!important;
}
.newPasswordContinueBtn{
    margin-right: 3vw!important;
    font-weight: bold!important;;
    background-color: rgb(0, 100, 255)!important;
    color: white!important;
    font-size: 2.5vw!important;
}
.newPasswordDialogBox{
    width: 90%!important;
}
.newPasswordInput{
    width: 100%!important;
    margin-bottom: 4vw!important;
    margin-top: 4vw!important;
    /* height: 80px!important; */

}
.newPasswordInput label{ 
    font-size: 3.5vw!important;
}
.confirmPasswordInput label{ 
    font-size: 3.5vw!important;
}

.newPasswordHeadTitle{
    width: 100%;
    font-weight: bold!important;
    font-size: 30px!important;
}
.newPasswordTitle{
    width: 90%!important;
    font-size: 24px!important;
}
}

@media all and (min-width: 250px) and (max-width: 545px) {
    .forgotPasswordDialogBox{
        width: 90%!important;
        /* height: 10vw; */
    }
    .forgotPasswordInput{
        width: 100%!important;
        margin-top: 5px!important;
    
    }
    .forgotPasswordTitle{
        width: 90%!important;
       font-size: 18px!important;
    
    }
    .forgotPasswordHeadTitle{
        font-weight: bold!important;
        font-size: 22px!important;
    }
    .forgotPasswordCancelBtn{
        margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: rgb(26, 25, 25)!important;
        font-size: 2vw!important;
    }
    .forgotPasswordContinueBtn{
        margin-right: 3vw!important;
        font-weight: bold!important;;
        background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-size: 2vw!important;
}
   
    /* OTP */
    .otpDialogBox{
        width: 90%!important;
        display: flex!important;
        justify-content: center!important;
    }
    .otpInput{
        width: 40%!important;
        margin-top: 15px!important;
    
    }
    .otpTitle{
        width: 90%!important;
       font-size: 13.5px!important;
    
    }
    .otpHeadTitle{
        font-weight: bold!important;
        font-size: 20px!important;
    }
    .otpCancelBtn{
        margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: rgb(26, 25, 25)!important;
        font-size: 2vw!important;
    }
    .otpContinueBtn{
        margin-right: 1.3vw!important;
        font-weight: bold!important;;
        background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-size: 2vw!important;
    }
    .otpResendBtn{
        margin-right: 1.3vw!important;;
        font-size: 2vw!important;;
        font-weight: bold!important;;
    }
    .timer{
        color: brown;  margin-right: 22vw;font-size:2.5vw
    }

    /* New Password */
.newPasswordCancelBtn{
    margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: rgb(26, 25, 25)!important;
        font-size: 2vw!important;
}
.newPasswordContinueBtn{
    margin-right: .5vw!important;
        font-weight: bold!important;;
        background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-size: 2vw!important;
}
.newPasswordDialogBox{
    width: 90%!important;
}
.newPasswordInput{
    width: 100%!important;
    margin-top: 5px!important;
    /* height: 80px!important; */

}
.newPasswordInput label{ 
    font-size: 5vw!important;
}
.confirmPasswordInput label{ 
    font-size: 5vw!important;
}

.newPasswordHeadTitle{
    width: 100%;
    font-weight: bold!important;
    font-size: 22px!important;
}
.newPasswordTitle{
    width: 90%!important;
    font-size: 18px!important;
}
}

@media all and (min-width: 250px) and (max-width: 545px) {
    .forgotPasswordDialogBox{
        width: 90%!important;
    }
    .forgotPasswordInput{
        width: 100%!important;
        margin-top: 15px!important;
    
    }
    .forgotPasswordTitle{
        width: 90%!important;
       font-size: 18px!important;
    
    }
    .forgotPasswordHeadTitle{
        font-weight: bold!important;
        font-size: 22px!important;
    }
    .forgotPasswordCancelBtn{
        margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: rgb(26, 25, 25)!important;
        font-size: 3.5vw!important;
    }
    .forgotPasswordContinueBtn{
        margin-right: .5vw!important;
        font-weight: bold!important;;
        background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-size: 3.5vw!important;
}
   
    /* OTP */
    .otpDialogBox{
        width: 90%!important;
        display: flex!important;
        justify-content: center!important;
    }
    .otpInput{
        width: 60%!important;
        margin-top: 15px!important;
    
    }
    .otpTitle{
        width: 90%!important;
       font-size: 13.5px!important;
    
    }
    .otpHeadTitle{
        font-weight: bold!important;
        font-size: 20px!important;
    }
    .otpCancelBtn{
        margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: rgb(26, 25, 25)!important;
        font-size: 2.5vw!important;
    }
    .otpContinueBtn{
        margin-right: 1.3vw!important;
        font-weight: bold!important;;
        background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-size: 2.5vw!important;
    }
    .otpResendBtn{
        margin-right: 1.3vw!important;;
        font-size: 2.5vw!important;;
        font-weight: bold!important;;
    }
    .timer{
        color: brown;  margin-right: 7vw;font-size:4vw
    }

    /* New Password */
.newPasswordCancelBtn{
    margin-right: 1.3vw!important;
        font-weight: bold!important;
        background-color: rgb(207, 207, 214)!important;
        color: rgb(26, 25, 25)!important;
        font-size: 3.5vw!important;
}
.newPasswordContinueBtn{
    margin-right: .5vw!important;
        font-weight: bold!important;;
        background-color: rgb(0, 100, 255)!important;
        color: white!important;
        font-size: 3.5vw!important;
}
.newPasswordDialogBox{
    width: 90%!important;
}
.newPasswordInput{
    width: 100%!important;
    margin-top: 5vw!important;
    
    /* height: 80px!important; */

}
.confirmPasswordInput{
    width: 100%!important;
    margin-top: 5vw!important;
    
    /* height: 80px!important; */

}
.newPasswordTitle{
    width: 90%!important;
    font-size: 14px!important;
}

.newPasswordHeadTitle{
    width: 100%;
    font-weight: bold!important;
    font-size: 22px!important;
}
}