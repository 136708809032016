.formContainer {
    width: 100%;
    background-color: var(--background-color);
}

.signinContainer {
    width: 30%;
    margin: auto;
    position: absolute;
    z-index: 1;
    top: 275px;
    left: 700px;
    /* background-color: var(--background-color); */

}

.logo {
    width: 20%;
    height: 50px;
    position: absolute;
    left: 180px;
    top: 40px;
}

.leftImage {
    width: 40%;
    height: 550px;
    position: absolute;
    left: 30px;
    top: 80px;
}

.containerBox {
    width: 35%;
    height: 250px;
    position: absolute;
    left: 700px;
    top: 100px;
}

.signInText {
    color: var(--primary-color);
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.signInButton {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    background-color: var(--primary-color);
    line-height: normal;
    font-family: Poppins;
    border-radius: 25px;
    width: 170px;
}

.arrowImage {
    position: absolute;
    top: 223px;
    left: 125px;
}

.rightBox {
    position: absolute;
    right: 0;
    top: 16vw;
}

.lineImage {
    position: absolute;
    top: 275px;
    left: 5px;
}

.orImage {
    position: absolute;
    top: 270px;
    left: 150px;
}

.signupText {
    color: var(--primary-color);
}

.signupLink {
    text-decoration: none;
    color: var(--primary-color);
}

.getStartedText {
    color: var(--primary-color);
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -40px;
}
.linkedinIcon{
    margin-top: 10px;
    color: var(--primary-color);
    font-size: 30px;
    cursor: pointer;
}
.welcomeText {
    color: var(--primary-color);
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -25px;
}

.managementText {
    color: var(--primary-color);
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -30px;
}